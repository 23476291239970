import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Breadcrumb} from "gatsby-plugin-breadcrumb";

const NotFoundPage = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO title="404: Page non trouvée" />
      <div className="container content-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Page non trouvée"
          />

          <h1 className="title is-1">Page non trouvée</h1>
        </section>

        <section className="section page-content">
          <p>La page que vous recherchez n'existe pas.</p>
          <div className="has-text-centered">
            <Link to="/" className="button is-centered">Retour à l'accueil</Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default NotFoundPage
